
import { SimulationTrainingState } from "@/store/modules/simulation-training";
import Vue from "vue";

import SimulationItem from "../simulation-item/index.vue";

export default Vue.extend({
  data() {
    return {};
  },
  computed: {
    filteredSimulationList(): SimulationTrainingState["simulationTrainingList"] {
      return this.$store.getters["simulationTrainingModule/filteredSimulationTrainingList"];
    }
  },
  methods: {},
  components: { SimulationItem }
});
