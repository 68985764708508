
import Vue from "vue";
import SearchInput from "@/components/SearchInput.vue";
import CreateSimulationModal from "../create-edit-simulation-modal/index.vue";

export default Vue.extend({
  components: { SearchInput, CreateSimulationModal },
  data() {
    return {
      createSimulationModalVisible: false
    };
  },
  methods: {
    searchInputChange(value) {
      this.$store.commit("simulationTrainingModule/setSearchText", value);
    },
    createSimulationClick() {
      this.$store.commit("simulationTrainingModule/resetEditSimulationTraining");
      this.createSimulationModalVisible = true;
    }
  }
});
