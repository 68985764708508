
import { modifyEnumInformation, SimulationTraining } from "@/api/simulation-training";
import { ElForm } from "element-ui/types/form";
import { isEmpty } from "lodash-es";
import Vue, { PropType } from "vue";
import AddTags from "../add-tags/index.vue";
import * as api from "@/api";
import { Message } from "element-ui";
import { myConfirm } from "@/utils/confirm";
import { uploadFile } from "@/utils/upload-file";
export default Vue.extend({
  props: {
    visible: { type: Boolean },
    item: { type: Object as PropType<SimulationTraining> }
  },
  data() {
    return {
      ruleForm: {
        name: "",
        time: undefined as string[] | undefined,
        grade: "",
        major: ""
      },
      rules: {
        name: [
          { required: true, message: "请输入实训名称", trigger: "blur" },
          { min: 1, max: 20, message: "长度在 1 到 20 个字符", trigger: "blur" }
        ],
        time: [{ required: true, message: "请选择起止时间" }],
        grade: [{ required: true, message: "请选择年级" }],
        major: [{ required: true, message: "请选择专业" }]
      },
      logo: "",
      gradeOptions: [] as { value: string; label: string }[],
      majorOptions: [] as { value: string; label: string }[],
      uploadableExtensions: ["png", "jpeg", "jpg"]
    };
  },
  computed: {
    isAdmin(): boolean {
      return this.$store.getters["isAdmin"];
    }
  },
  methods: {
    closeModal() {
      this.$emit("update:visible", false);
    },
    handleConfirm() {
      (this.$refs["createEditCatalogueForm"] as ElForm).validate(async valid => {
        if (valid) {
          await this.$store.dispatch("simulationTrainingModule/createEditSimulationTraining", {
            beginTime: this.ruleForm.time![0],
            endTime: this.ruleForm.time![1],
            logo: this.logo,
            title: this.ruleForm.name,
            major: this.ruleForm.major,
            grade: this.ruleForm.grade
          });
          this.closeModal();
        } else {
          return;
        }
      });
    },
    async deleteGrade(tag) {
      myConfirm(this, {
        text: "确认删除此项？",
        resolve: async () => {
          this.gradeOptions.splice(
            this.gradeOptions.findIndex(v => v.value === tag.value),
            1
          );
          const res = await modifyEnumInformation({
            groupCode: "grade",
            txt: [...this.gradeOptions.map(v => v.value)].join(",")
          });
          if (!api.error(res)) {
            Message.success("删除年级成功");
          }
        }
      });
    },
    async deleteMajor(tag) {
      myConfirm(this, {
        text: "确认删除此项？",
        resolve: async () => {
          this.majorOptions.splice(
            this.majorOptions.findIndex(v => v.value === tag.value),
            1
          );
          const res = await modifyEnumInformation({
            groupCode: "major",
            txt: [...this.majorOptions.map(v => v.value)].join(",")
          });
          if (!api.error(res)) {
            Message.success("删除专业成功");
          }
        }
      });
    },
    async addGrade(value) {
      const res = await modifyEnumInformation({
        val: "",
        groupCode: "grade",
        txt: [...this.gradeOptions.map(v => v.value), value].join(",")
      });
      if (!api.error(res)) {
        this.gradeOptions.push({ value, label: value });
        Message.success("添加年级成功");
      }
    },
    async addMajor(value) {
      const res = await modifyEnumInformation({
        val: "",
        groupCode: "major",
        txt: [...this.majorOptions.map(v => v.value), value].join(",")
      });
      if (!api.error(res)) {
        this.majorOptions.push({ value, label: value });
        Message.success("添加专业成功");
      }
    },
    uploadModel(_file) {
      const file = _file.raw;
      const index = file.name.lastIndexOf(".");
      const type: string = file.name.slice(index + 1);
      if (!this.uploadableExtensions.some(v => v === type.toLowerCase())) {
        this.$message.warning(`暂时不支持该格式, 请上传${this.uploadableExtensions.join(" ")}文件`);
        return;
      }
      const setFile = data => {
        this.logo = process.env.VUE_APP_BORON + "/" + data.filePath;
      };
      uploadFile(file, setFile, this.$store.state.sessionId);
    },
    deleteFile() {
      this.logo = "";
    }
  },
  watch: {
    visible: {
      async handler(val) {
        if (val) {
          const majorRes = await api.getEnums({ group: "major" });
          const gradeRes = await api.getEnums({ group: "grade" });
          if (!api.error(majorRes) && !api.error(gradeRes)) {
            this.gradeOptions = gradeRes.data[0].txt.split(",").map(v => ({ value: v, label: v }));
            this.majorOptions = majorRes.data[0].txt.split(",").map(v => ({ value: v, label: v }));
          }
          if (!isEmpty(this.item)) {
            this.ruleForm.name = this.item.title;
            this.ruleForm.grade = this.item.grade;
            this.ruleForm.major = this.item.major;
            this.ruleForm.time = [this.item.beginTime, this.item.endTime];
            this.logo = this.item.logo;
          } else {
            this.$nextTick(() => {
              (this.$refs["createEditCatalogueForm"] as ElForm).resetFields();
              this.logo = "";
            });
          }
        }
      }
    }
  },
  components: { AddTags }
});
