
import Vue, { PropType } from "vue";
import Ellipsis from "@/components/Ellipsis.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import CreateSimulationModal from "../create-edit-simulation-modal/index.vue";
import moment from "moment";
import { SimulationTraining } from "@/api/simulation-training";
import { myConfirm } from "@/utils/confirm";
const statusColor = {
  green: "#519259",
  red: "#c85c5c",
  yellow: "#FFA136",
  grey: "#929292"
};
enum ProgressStatus {
  notStart,
  inProgress,
  ended
}
enum PublicStatus {
  notPublic,
  public
}
const ProgressStatusText = {
  [ProgressStatus.notStart]: "未开始",
  [ProgressStatus.inProgress]: "进行中",
  [ProgressStatus.ended]: "已结束"
};
const PublicStatusText = {
  [PublicStatus.notPublic]: "未发布",
  [PublicStatus.public]: "已发布"
};
export default Vue.extend({
  components: { Ellipsis, SvgIcon, CreateSimulationModal },
  props: {
    item: { type: Object as PropType<SimulationTraining> }
  },
  data() {
    return {
      editSimulationModalVisible: false,
      defaultCover: require("../../../assets/product_thumbnail.png")
    };
  },
  computed: {
    progressStatus(): string {
      if (moment() > moment(this.item.endTime)) return ProgressStatusText[ProgressStatus.ended];
      if (moment() < moment(this.item.beginTime))
        return ProgressStatusText[ProgressStatus.notStart];
      return ProgressStatusText[ProgressStatus.inProgress];
    },
    publicStatus(): string {
      return PublicStatusText[this.item.status ?? 0];
    },
    progressStatusColor(): string {
      if (moment() > moment(this.item.endTime)) return statusColor.grey;
      if (moment() < moment(this.item.beginTime)) return statusColor.grey;
      return statusColor.yellow;
    },
    publicStatusColor(): string {
      return this.item.status === PublicStatus["notPublic"] ? statusColor.grey : statusColor.green;
    },
    coverSrc(): string {
      return this.item.logo ? this.item.logo : this.defaultCover;
    }
  },
  methods: {
    edit() {
      this.editSimulationModalVisible = true;
      this.$store.commit("simulationTrainingModule/setEditSimulationTraining", this.item);
    },
    deleteItem() {
      myConfirm(this, {
        text: "确认删除此项吗？",
        resolve: () => {
          this.$store.dispatch("simulationTrainingModule/deleteSimulationTraining", {
            id: this.item.id
          });
        }
      });
    },
    configSimulation() {
      this.$store.commit("simulationTrainingModule/setSelectedSimulationTraining", this.item);
      this.$store.commit("breadcrumbList", [
        {
          name: "定制仿真",
          path: "/collage/SimulationTraining"
        },
        {
          name: "实训课程"
        }
      ]);
      this.$router.push({ path: "/collage/SimulationTraining/practicalTrainingCourseConfigure" });
    }
  }
});
