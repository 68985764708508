
import Vue from 'vue';
import LeftRightLayout from '@/layout/left-right-layout/left-right-layout.vue';
import CatalogueHeader from '@/components/catalogue/catalogue-header.vue';
import CreateCatalogueModal from '@/components/catalogue/create-catalogue-modal.vue';
import CatalogueItem from '@/components/catalogue/catalogue-item.vue';
import { SimulationTrainingState } from '@/store/modules/simulation-training';
import TopBotLayout from '@/layout/top-bot-layout/top-bot-layout.vue';
import ToolHeader from '@/features/simulation-training/tool-header/index.vue';
import SimulationList from '@/features/simulation-training/simulation-list/index.vue';

export default Vue.extend({
  components: {
    LeftRightLayout,
    CatalogueHeader,
    CreateCatalogueModal,
    CatalogueItem,
    TopBotLayout,
    ToolHeader,
    SimulationList,
  },
  created() {
    this.$store.commit('breadcrumbList', [
      {
        name: '定制仿真',
        path: 'simulationTraining',
      },
    ]);
    this.$store.dispatch('simulationTrainingModule/init');
  },
  computed: {
    editCatalogueName() {
      return this.$store.getters['simulationTrainingModule/editCatalogueName'];
    },
    catalogueList(): SimulationTrainingState['catalogueList'] {
      return this.sortList(this.$store.state.simulationTrainingModule.catalogueList);
    },
  },
  data() {
    return {
      createCatalogueModalVisible: false,
    };
  },
  methods: {
    sortList(list: any[]) {
      return list.sort((a, b) => {
        if (a.createNickName === '超级管理员') {
          return -1;
        }
        return 0;
      });
    },
    createCatalogue() {
      this.$store.commit('simulationTrainingModule/setEditCatalogue', {});
      this.createCatalogueModalVisible = true;
    },
    createOrEditCatalogueConfirm(inputValue) {
      this.$store.dispatch('simulationTrainingModule/createEditSimulationTrainingDirectory', {
        inputValue,
      });
    },
    isSelected(id) {
      return id === this.$store.getters['simulationTrainingModule/selectedCatalogueId'];
    },
    isCreateBySelfOrAdmin(creatorId) {
      return creatorId === this.$store.state.userInfo.userId || this.$store.getters.isAdmin;
    },
    selectCatalogue(item) {
      this.$store.commit('simulationTrainingModule/setSelectedCatalogue', item);
      this.$store.dispatch('simulationTrainingModule/getSimulationTrainingList', {
        catalogueId: item.id,
      });
    },
    editCatalogue(item) {
      this.$store.commit('simulationTrainingModule/setEditCatalogue', item);
      this.createCatalogueModalVisible = true;
    },
    deleteCatalogue(item) {
      this.$store.dispatch('simulationTrainingModule/deleteCatalogue', item);
    },
  },
});
