
import Vue, { PropType } from "vue";
export default Vue.extend({
  props: {
    tagList: { type: Array as PropType<{ value: string; label: string }[]> },
    addText: { type: String }
  },
  data() {
    return { inputVisible: false, inputValue: "" };
  },
  methods: {
    handleClose(tag) {
      this.$emit("onDelete", tag);
      // this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        (this.$refs.saveTagInput as any).$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.$emit("onAdd", inputValue);
        // this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    }
  }
});
